<template>
  <v-container fluid class="hero">
    <base-dialog-notification ref="passwordResetConfirm" />
    <v-row align="center" justify="center">
      <v-col lg="12" md="12" cols="12">
<!--        <v-card elevation="0" class="pt-4 light_opacity">-->
          <v-window v-model="step">
            <v-window-item :value="0">
              <v-row>
                <v-col cols="12">
                  <v-card-title class="py-10">
                    <div class="text-center row justify-center">
                      <h1 class="font-weight-light" style="color: #3c4858; font-size: 36px">
                        <img width="40%" src="@/assets/FairwageLogo.png" alt="Fair Wage Logo" class="contain"/>
                      </h1>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div>
                      <h1 class="text-center text-md-h3 primary--text my-4">
                        Reset Password
                      </h1>
                      <h3 class="text-center text-md-h3 mb-5">
                        Please create and confirm a new password for your account.
                      </h3>
                    </div>
                  </v-card-text>
                  <v-card-text>
                    <v-form
                      ref="registerFrm"
                      @submit.prevent="resetPassword"
                      lazy-validation
                    >
                      <v-text-field
                        v-model="reset.password"
                        :append-icon="toggled == true ? 'mdi-eye' : 'mdi-eye-off'"
                        :append-icon-cb="switchToggle"
                        label="Password"
                        name="password"
                        outlined
                        :rules="passwordRules"
                        :type="toggled == true ? 'text' : 'password'"
                        required
                        @click:append="switchToggle"
                      />
                      <v-text-field
                        v-model="reset.confirm"
                        :append-icon="toggled == true ? 'mdi-eye' : 'mdi-eye-off'"
                        :append-icon-cb="switchToggle"
                        label="Confirm"
                        name="confirm"
                        outlined
                        :rules="confirmPasswordRules"
                        :type="toggled == true ? 'text' : 'password'"
                        @update:error="checkPassword"
                        required
                        @click:append="switchToggle"
                      />
                      <div>
                      <v-btn
                          color="primary"
                          elevation="0"
                          large
                          block
                          class="btn"
                          type="submit"
                          :disabled="valid"
                      >Change Password</v-btn>
                      </div>
<!--                      <vue-recaptcha-->
<!--                        ref="recaptcha"-->
<!--                        @verify="onVerify"-->
<!--                        @expired="onExpired"-->
<!--                        sitekey="6LctxIYkAAAAAF1JFgz-HiwnJjFr8Wry4pEaGf3k"-->
<!--                        class="recaptchax"-->
<!--                        @click="executeRecaptcha"-->
<!--                        :loadRecaptchaScript="true"-->
<!--                      >-->
<!--                      </vue-recaptcha>-->
                      <div class="text-center mt-4">

                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
<!--        </v-card>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import VueRecaptcha from "vue-recaptcha";
export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      step: 0,
      toggled: false,
      valid: true,
      reset: {
        password: "",
        confirm: "",
      },
      // vueAppRecaptchaSiteKey: process.env.VUE_RECAPTCHA_SITE_KEY,
      // vueAppRecaptchaSecretKey: process.env.VUE_RECAPTCHA_SECRET_KEY,
      passwordRules: [
        (v) => !!v || "Please type password.",
        (v) => (v && v.length >= 6) || "minimum 6 characters",
      ],
      confirmPasswordRules: [
        (v) => !!v || "type confirm password",
        (v) =>
          v === this.reset.password ||
          "The password confirmation does not match.",
      ],
    };
  },
  methods: {
    switchToggle() {
      this.toggled = !this.toggled;
    },
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
    onVerify: function (response) {
      console.log("Verify: " + response);
      return (this.valid = !this.valid);
    },
    onExpired: function () {
      console.log("Expired");
      return (this.valid = !this.valid);
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    checkPassword(invalid) {
      // correct: false
      if (true == invalid) {
        this.valid = true;
        console.log('True')
      } else {
        this.valid = false;
        console.log('false')
      }
    },
    async resetPassword() {
      const self = this;
      await this.$apollo
        .mutate({
          mutation: gql`
            mutation resetPassword($resetToken: String!, $password: String!) {
              resetPassword(resetToken: $resetToken, password: $password) {
                id
                updated
              }
            }
          `,
          variables: {
            resetToken: self.$route.params.id,
            password: self.reset.password,
          },
        })
        .then(async (response) => {
          await this.$refs.passwordResetConfirm.open("Success", "Your password was successfully reset.", "success")
          this.$router.push("/")
        }).catch((e) => {
            this.$refs.passwordResetConfirm.open("Error", "There was a system error while attempting to reset your password. Please try again or contact support.", "error")
        })
    },
  },
};
</script>

<style scoped>
.hero {
  height: 100%;
}
</style>
